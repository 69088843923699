@import url(https://fonts.googleapis.com/css2?family=Big+Shoulders+Display:wght@400;500;700&family=Open+Sans&family=Shrikhand&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@800&display=swap);
:root {
  --background-color: #e5d5c7;
  --accent-blue: #aab5c5;
  --accent-green: #fff1c2;
  --accent-tealBlue: #53616f;
  --button-color: #2c333a;
}

body {
  margin: 0;
  padding: 0;
  background-color: #e5d5c7;
  background-color: var(--background-color);
  font-family: "Open Sans";
}

.title {
  font-size: 5em;
  text-align: center;
  color: #001e3d;
}

h1 {
  font-family: "Shrikhand", cursive;
}

.mainContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-inline: 10em;
  min-height: 30em;
  margin-bottom: 20px;
}

.buttonStyle {
  color: white;
  background-color: #2c333a;
  background-color: var(--button-color);
  border: 1px solid #001e3d;
  border-radius: 0;
  padding: 10px 25px 10px;
  font-family: "Big Shoulders Display", cursive;
  letter-spacing: 0.1em;
}

.buttonStyle:hover {
  color: rgb(245, 239, 239);
  background-color: #073563;
  text-decoration: none;
}

@media screen and (max-width: 420px) {
  .title {
    font-size: 3em;
  }
  .mainContainer {
    padding-inline: 10px;
  }
}

.Navbar_navbarbuttonstyling__Wv0fU {
  color: white;
  background-color: #001e3d;
  border: 1px solid #001e3d;
  margin-left: 1vw;
  border-radius: 0;
}

.Navbar_navbarbuttonstyling__Wv0fU:hover {
  color: rgb(245, 239, 239);
  background-color: #073563;
}

.Navbar_navLink__34C-w {
  color: white;
}
#Navbar_navs__2cAMf {
  z-index: 2;
}
@media screen and (max-width: 1000px) {
  .Navbar_rftstitle__2Z8uc {
    font-size: 80%;
  }
  .Navbar_navbarbuttonstyling__Wv0fU {
    text-align: center;
    margin-left: 1vw;
    border-radius: 0;
    font-size: 50%;
  }
}
@media screen and (max-width: 500px) {
  .Navbar_rftstitle__2Z8uc {
    display: none;
  }
  .Navbar_navbarbuttonstyling__Wv0fU {
    text-align: center;
    margin-left: 1vw;
    border-radius: 0;
  }
  .Navbar_navbar__2KrFT {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
  }
  .Navbar_navbarSupportedContent__1FqSl {
    width: auto;
  }
}

.Sidebar_navbar__2Ta-g {
  height: 80px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
}

.Sidebar_logo__1g3f8 {
  padding-left: 50px;
  text-decoration: none;
  color: black;
}

.Sidebar_logo__1g3f8:hover {
  padding-left: 50px;
  text-decoration: none;
  color: black;
}

.Sidebar_menuBars__3T7bp {
  margin-left: 1rem;
  font-size: 1.5rem;
}

.Sidebar_navMenu__g0seQ {
  background-color: #060b26;
  width: 250px;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
}

.Sidebar_navMenu__g0seQ.Sidebar_active__2pKOI {
  left: 0;
  transition: 350ms;
}

.Sidebar_navTextTop__2PTsX {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 8px 0px 8px 0px;
  list-style: none;
}

.Sidebar_navTextTop__2PTsX a {
  text-decoration: none;
  color: #f5f5f5;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 0 16px;
}

.Sidebar_navMenuItems__3lv-c {
  width: 100%;
  padding: 0;
  margin: 0;
}

.Sidebar_navbarToggle__2gec5 {
  background-color: #060b26;
  width: 80%;
  height: 80px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
}

span {
  margin-left: 16px;
}

@media screen and (max-width: 1000px) {
  .Sidebar_logo__1g3f8 {
    padding-left: 10px;
  }
  .Sidebar_logo__1g3f8:hover {
    padding-left: 10px;
  }
}

@media screen and (max-width: 500px) {
  .Sidebar_logo__1g3f8 {
    padding-left: 5px;
  }
  .Sidebar_logo__1g3f8:hover {
    padding-left: 5px;
  }
  h3 {
    display: none;
  }
  .Sidebar_navMenu__g0seQ {
    width: 100%;
  }
}

.Footer_theFooter__2_pfK {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: #001e3d;
  width: 100%;
  color: white;
  padding: 30px;
}

.Footer_contactUs__1zdfi {
  height: 80%;
  margin-bottom: 50px;
}

.Footer_contactUsContent__3l71J h1 {
  margin-bottom: 5vh;
}
.Footer_contactUsContent__3l71J {
  -webkit-align-content: center;
          align-content: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.Footer_contactUsContent__3l71J input,
.Footer_contactUsContent__3l71J textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 3vh;
}
.Footer_contactUsContent__3l71J input::-webkit-input-placeholder, .Footer_contactUsContent__3l71J textarea::-webkit-input-placeholder {
  color: rgb(31, 30, 30);
}
.Footer_contactUsContent__3l71J input:-ms-input-placeholder, .Footer_contactUsContent__3l71J textarea:-ms-input-placeholder {
  color: rgb(31, 30, 30);
}
.Footer_contactUsContent__3l71J input::placeholder,
.Footer_contactUsContent__3l71J textarea::placeholder {
  color: rgb(31, 30, 30);
}
.Footer_submitContact__kK37G {
  background-color: #e5d5c7;
  border: none;
}

/* .subscribe {
  height: 80%;
  margin-top: 10%;
  grid-row: 1/2;
}

.subscribeContent {
  width: 70%;
  margin-left: 15%;
  margin-top: 7vh;
}

.subscribeContent p {
  text-align: center;
}

.subscribeContent input {
  width: 100%;
  padding: 10px;
  margin-bottom: 3vh;
} */

.Footer_copyright__2AbKt p {
  text-align: center;
}
@media (max-width: 800px) {
  .Footer_theFooter__2_pfK {
    display: grid;
  }
  /* .subscribe {
    height: 80%;
    margin-top: 10%;
    grid-row: 2/3;
  }
  .subscribeContent {
    margin-top: 0vh;
  } */
}

.Home_image__3vU4r {
  position: fixed;
  z-index: -2;
  width: 100vw;
  top: 0;
  margin: 0;
}
.Home_tagline__14DnB {
  margin-left: 5vw;
  width: 100%;
  font-size: 6.5rem;
  letter-spacing: 0.2rem;
  margin: 13% 13% 5% 13%;
  background: -webkit-linear-gradient(rgb(59, 52, 146), rgb(108, 139, 231));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-color: white;
}
.Home_refBar__2XBTA {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: center;
          align-items: center;
  z-index: 1;
  width: 75%;
  box-shadow: 2px 1px 1px 1px;
  background-color: #001e3d;
  opacity: 0.7;
  min-height: 50px;
}
.Home_button__26UOG {
  color: white;
  background-color: #001e3d;
  border: 1px solid #001e3d;
  border-radius: 0;
  padding: 10px;
}
.Home_infocards__2kQhK {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 480px;
  padding: 35px;
  margin: 0;
  text-align: center;
  line-height: 1.6;
}
.Home_cardContainer__2V0vY {
  display: grid;
  grid-template-columns: 550px 550px;
  grid-gap: 10px;
}
.Home_ourMission__3S5OT {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  background-color: var(--accent-green);
  padding: 50px 30px 50px;
  text-align: center;
  max-width: 550px;
  box-shadow: 2px 2px 2px grey;
  padding-inline: 4em;
  text-align: justify;
}
.Home_contimage__3MzXV {
  box-shadow: 2px 2px 2px grey;
}
.Home_contactUsButton__3liEq {
  background-color: #201e50;
  color: white;
  font-size: 18px;
  height: auto;
  border: none;
  font-weight: bold;
  padding: 10px 20px 10px;
  margin-top: 10px;
  border: 1px 1px 1px 1px black;
}
.Home_contactUsButton__3liEq:hover {
  color: rgb(245, 239, 239);
  background-color: #073563;
}
.Home_imagecards__1VuGR {
  width: 580px;
  text-align: center;
  min-height: 550px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  float: left;
}

.Home_bottomContainer__3NO1R {
  margin-top: 400px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: var(--background-color);
  width: 100%;
  height: 100%;
  padding: 50px 0 50px 0;
}

.Home_tutoringResourcesCont__3bD23 {
  display: grid;
  grid-template-columns: 550px 550px;
  grid-gap: 15px;
}

.Home_tutoringResources__1kJFa {
  margin-top: 10%;
  background-color: #598392;
  padding: 20px 20px 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
}

.Home_tutoringResourcesCards__2yVjd {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  margin: 40px 0 40px;
  background-color: var(--accent-blue);
  max-width: 550px;
  box-shadow: 3px 3px 3px grey;
}
.Home_registerbuttonstyling__7eMfo {
  color: white;
  background-color: #001e3d;
  border: 1px solid #001e3d;
  margin-left: 1vw;
  border-radius: 0;
  padding: 5px 25px 5px;
}
.Home_registerbuttonstyling__7eMfo:hover {
  color: rgb(245, 239, 239);
  background-color: #073563;
}
.Home_ourProgramsContainer__3vQou {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 30px;
}
.Home_summerCamp__26VY_ {
  background-color: var(--accent-green);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 40px 30px 40px;
  box-shadow: 3px 3px 3px grey;
}
.Home_peerClassCard__lq5lx {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  background-color: var(--accent-green);
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: center;
          align-items: center;
  padding: 40px 30px 40px;
  box-shadow: 3px 3px 3px grey;
}
.Home_blogs__3mSQp {
  max-height: 1000px;
}
.Home_gridcontainer__2FFHQ {
  display: grid;
  grid-template-columns: 300px 300px 300px;
  grid-auto-rows: 1fr;
  grid-gap: 20px;
  -webkit-justify-content: center;
          justify-content: center;
}

@media screen and (max-width: 1200px) {
  .Home_contimage__3MzXV {
    display: none;
  }
  .Home_cardContainer__2V0vY {
    grid-template-columns: 90%;
    grid-gap: 10px;
  }
  .Home_tutoringResourcesCont__3bD23 {
    grid-template-columns: 400px;
  }
}

@media screen and (max-width: 1000px) {
  .Home_cardContainer__2V0vY {
    grid-template-columns: 90%;
    grid-gap: 10px;
  }
}

@media screen and (max-width: 500px) {
  .Home_image__3vU4r {
    position: absolute;
    width: 100vw;
  }
  .Home_bottomContainer__3NO1R {
    margin-top: 0;
  }
  .Home_tagline__14DnB {
    font-size: 2rem;
    margin-left: 10%;
    margin-top: 5%;
  }
  .Home_refBar__2XBTA {
    margin: 20px;
    width: 90%;
  }
  .Home_button__26UOG {
    font-size: 10px;
    padding: 2px;
  }
  .Home_cardContainer__2V0vY {
    display: grid;
    grid-template-columns: 340px;
    grid-gap: 10px;
  }
  .Home_tutoringResourcesCont__3bD23 {
    grid-template-columns: 340px;
  }
}

.IndAbout_title__1BzoR {
  text-align: center;
  font-weight: bold;
  font-size: 50px;
}
.IndAbout_container__3OFdU {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.IndAbout_item__27sT3 {
  min-height: 70vh;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.IndAbout_image__2VRtO {
  width: 200px;
  max-height: 400px;
  min-height: 200px;
  object-fit: cover;
  margin-bottom: 20px;
}
.IndAbout_grid__1fOCE {
  display: grid;
  grid-template-columns: 2fr 1fr;
}
.IndAbout_box__3IQiI {
  background-color: var(--accent-tealBlue);
  width: 65%;
  position: relative;
  padding: 3%;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.IndAbout_info__hGIjf {
  /* width: 70%; */
  color: white;
  padding-top: 30px;
  /* text-align: justify; */
}
.IndAbout_namepos__1pdmS {
  text-align: right;
  color: white;
  bottom: 3%;
  right: 3%;
}

@media screen and (max-width: 1000px) {
  .IndAbout_namepos__1pdmS {
    text-align: center;
    margin: 20px;
  }
  .IndAbout_info__hGIjf {
    width: auto;
    text-align: justify;
  }
  .IndAbout_imageCenter__NtQfL {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 30px;
  }
  .IndAbout_grid__1fOCE {
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }

  .IndAbout_container__3OFdU {
    grid-row: 1;
    padding-top: 10px;
  }
  .IndAbout_box__3IQiI {
    padding: 10px 25px 10px;
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 500px) {
  .IndAbout_box__3IQiI {
    width: 99%;
    height: 100%;
  }
  .IndAbout_title__1BzoR {
    font-size: 30px;
    margin-top: 20%;
  }
}

.About_title__ezTei {
  font-size: 60px;
  text-align: center;
  font-weight: bold;
  margin: 20px;
}

.About_list__2wEoc {
  list-style: none;
  justify-self: center;
  margin: 0;
  padding: 0;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.About_map__2654T {
  height: 80vh;
}

@media screen and (max-width: 900px) {
  .About_map__2654T {
    width: 96%;
  }
}

.Marker_pin__3XPC2 {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #00cae9;
  position: absolute;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
  z-index: 0;
}
.Marker_pin__3XPC2:after {
  content: "";
  width: 14px;
  height: 14px;
  margin: 8px 0 0 8px;
  background: #e6e6e6;
  position: absolute;
  border-radius: 50%;
}
.Marker_pin__3XPC2:hover .Marker_modal__VH3nd {
  display: block;
}

.Marker_modal__VH3nd {
  background-color: white;
  position: absolute;
  -webkit-transform: rotate(45deg) translateX(-30%);
          transform: rotate(45deg) translateX(-30%);
  display: none;
  border-radius: 10px;
}

.Login_loginBox__3wu6n {
  width: 75%;
  margin: auto;
  margin-top: 50px;
  background: #f0f0f0;
  box-shadow: 2px 2px 1px 1px grey;
  padding: 20px;
  /* min-height: 20em; */
}
.Login_oAuth__2nUNH {
  grid-row: 2/3;
  grid-column: 2/3;
  height: 90%;
  display: block;
  -webkit-justify-content: center;
          justify-content: center;
}
.Login_loginBox__3wu6n {
  text-align: center;
  padding-top: 25px;
  font-size: 250%;
  color: #073563;
  grid-row: 1/2;
  grid-column: 1/3;
}
.Login_col-sm__Oxwh0 {
  background-color: red;
}
form {
  width: 100%;
  height: 100%;
}
.Login_typingInput__1xpvI {
  border: none;
  /* margin-left: 20%; */
  width: 60%;
  padding: 5px;
  padding-left: 10px;
  margin-bottom: 10px;
}
.Login_checkingInput__3PRvz {
  margin-left: 20%;
  margin-bottom: 5%;
}
input::-webkit-input-placeholder {
  color: rgb(65, 60, 60);
}
input:-ms-input-placeholder {
  color: rgb(65, 60, 60);
}
input::placeholder {
  color: rgb(65, 60, 60);
}
.Login_submitButton__2pEuG {
  text-align: center;
  color: white;
  background-color: #062849;
  border: none;
  padding: 5px;
  width: 30%;
  /* margin-left: 35%; */
}
.Login_rememberMe__1OWdD {
  margin: 0;
  margin-left: 5px;
}

.Login_signInWithGoogleButton__kMSb3 {
  border: none;
  padding: 5px;
  padding-left: 16px;
  width: 50%;
  background-color: #e7e7e7;
  border-radius: 1px;
  margin-left: 25%;
  margin-top: 15%;
}
.Login_signInWithGoogleButton__kMSb3 img {
  width: 10%;
  height: 10%;
  float: left;
}
.Login_signInWithGoogleButton__kMSb3 span {
  margin: 0;
  padding: 0;
  padding-left: 16px;
  float: left;
}
@media screen and (max-width: 1000px) {
  .Login_signInWithGoogleButton__kMSb3 span {
    font-size: 50%;
    padding-left: 10px;
    padding-top: 2px;
  }
  .Login_loginBox__3wu6n {
    margin-top: 15%;
  }
}
@media screen and (max-width: 600px) {
  .Login_loginBox__3wu6n {
    margin-top: 30%;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr 1fr;
  }
  .Login_loginButtons__1gaOg {
    border: none;
    height: 100%;
    width: 100%;
    margin-top: auto;
  }
  .Login_oAuth__2nUNH {
    height: 100%;
    width: 100%;
    margin-top: auto;
    grid-row: 3/4;
    grid-column: 1/2;
  }
  .Login_signInWithGoogleButton__kMSb3 {
    margin-top: 10%;
    height: 22%;
    padding: 0;
  }

  .Login_signInWithGoogleButton__kMSb3 img {
    width: 27%;
    height: 70%;
    padding-left: 16px;
    float: left;
  }
  .Login_signInWithGoogleButton__kMSb3 span {
    padding-left: 10px;
    padding-top: 2px;
  }
  .Login_submitButton__2pEuG {
    margin-top: 5%;
  }
}

.Blog_cont__X2iKi {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-inline: 2%;
}

.Blog_edit__1O4yq {
  top: 14%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-self: center;
          align-self: center;
  -webkit-justify-content: center;
          justify-content: center;
  left: 10%;
  font-size: 20px;
  color: black;
}
/* 
.edit :hover {
  cursor: pointer;
} */

.Blog_pageTitle__T24h- {
  font-size: 5rem;
  text-align: center;
  color: #001e3d;
}

.Blog_blogCard__462ok :hover {
  cursor: pointer;
}

.Blog_subtext__3qoLR {
  display: inline;
}

.Blog_gridcontainer__1zLhn {
  display: grid;
  grid-template-columns: 400px 400px 400px;
  grid-gap: 10px;
  -webkit-justify-content: center;
          justify-content: center;
}

@media screen and (max-width: 1000px) {
  .Blog_gridcontainer__1zLhn {
    grid-template-columns: 280px;
  }
}

.Registration_title__3c0cs {
  font-size: 40px;
  text-align: center;
  font-weight: bold;
  margin: 20px;
}

.Registration_subtitle__Eu5PC {
  max-width: 70%;
  text-align: center;
  margin: 20px 0 20px;
}

.Registration_container__1E4Bv {
  display: grid;
  grid-template-columns: 2fr 1fr;
  background-color: var(--accent-tealBlue);
  padding: 60px;
  margin-bottom: 40px;
}

.Registration_text__RrR-i {
  color: white;
}

.Registration_clicks__1cSqS {
  background-color: #696464;
  color: rgb(248, 243, 236);
  text-align: center;
  width: 200px;
  height: 50px;
  border: none;
}

.Registration_button__1W-S9 {
  display: block;
  text-align: center;
  margin: 30px;
}

#Registration_moreInfo__1fqSK {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

@media screen and (max-width: 900px) {
  .Registration_container__1E4Bv {
    grid-template-columns: 1fr;
  }
  .Registration_text__RrR-i {
    text-align: center;
  }
}

.Blog-Page_contain__2yCcB {
  margin-top: 5%;
  margin-left: 20%;
  margin-right: 20%;
}

.Blog-Page_blogCard__2ufwm :hover {
  cursor: pointer;
}

.Blog-Page_blog__1FukM {
  background-color: white;
  padding: 4%;
}
.Blog-Page_content__3m2bp {
  margin: 7%;
  margin-bottom: 10%;
}

.Blog-Page_title__bBb7r {
  text-align: center;
  font-size: 2.5rem;
  margin-inline: 20px;
}

.Blog-Page_row__qqVwg {
  margin-top: 30px;
}

.Blog-Page_text__3WSPE {
  padding-bottom: 10%;
  border-bottom: 1px solid black;
}

.Blog-Page_back__1hx9H {
  position: fixed;
  top: 14%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-self: center;
          align-self: center;
  -webkit-justify-content: center;
          justify-content: center;
  left: 10%;
  font-size: 20px;
  color: black;
}

.Blog-Page_back__1hx9H :hover {
  cursor: pointer;
}

.Blog-Page_icon__2ff_X {
  display: -webkit-flex;
  display: flex;
  -webkit-align-self: center;
          align-self: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding-right: 5px;
}
.Blog-Page_gridcontainer__2YNCY {
  display: grid;
  grid-template-columns: 300px 300px 300px;
  grid-auto-rows: 1fr;
  grid-gap: 10px;
  -webkit-justify-content: center;
          justify-content: center;
}

@media screen and (max-width: 900px) {
  .Blog-Page_contain__2yCcB {
    margin-left: 1%;
    margin-right: 1%;
  }
  .Blog-Page_back__1hx9H {
    left: 4%;
    color: white;
  }
  .Blog-Page_gridcontainer__2YNCY {
    grid-template-columns: 400px;
  }
}

.BlogPageEdit_contain__3YAyt {
  margin-left: 17%;
  margin-right: 17%;
  margin-bottom: 10%;
}

.BlogPageEdit_blog__2Q-MX {
  background-color: white;
  padding: 4%;
}
.BlogPageEdit_content__1YD4_ {
  margin: 7%;
  margin-bottom: 10%;
}

.BlogPageEdit_title__hoUiT {
  text-align: center;
  font-size: 3rem;
}

.BlogPageEdit_row__1uEtc {
  margin-top: 30px;
}

.BlogPageEdit_text__1uGbc {
  padding-bottom: 10%;
  border-bottom: 1px solid black;
}

.BlogPageEdit_icon__3CaID {
  display: -webkit-flex;
  display: flex;
  -webkit-align-self: center;
          align-self: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding-right: 5px;
}

.BlogPageEdit_button__1nz9J {
  background-color: #696464;
  color: rgb(248, 243, 236);
  text-align: center;
  width: 100px;
  height: 50px;
  border: none;
  margin-top: 25px;
  margin-bottom: 25px;
}

.BlogPageEdit_edit__1xNjy {
  font-size: 40px;
  margin-top: 20px;
  font-weight: bold;
}

@media screen and (max-width: 420px) {
  .BlogPageEdit_buttons__rCgVi {
    justify-self: center;
  }
}

.SignUp_signUpBox__-ktoT {
  width: 75%;
  margin: auto;
  background: #f0f0f0;
  box-shadow: 2px 2px 2px 2px grey;
  padding: 20px;
}
.SignUp_oAuth__Etusa {
  grid-row: 2/3;
  grid-column: 2/3;
  height: 90%;
  display: block;
  -webkit-justify-content: center;
          justify-content: center;
}
.SignUp_signUpBox__-ktoT {
  text-align: center;
  padding-top: 25px;
  font-size: 250%;
  color: #073563;
  grid-row: 1/2;
  grid-column: 1/3;
}
.SignUp_col-sm__2qyyF {
  background-color: red;
}
form {
  width: 100%;
  height: 100%;
}
.SignUp_typingInput__1104Y {
  border: none;
  /* margin-left: 20%; */

  padding: 10px;
  padding-left: 10px;
  margin-bottom: 10px;
}
.SignUp_checkingInput__36gNW {
  margin-left: 20%;
  margin-bottom: 5%;
}
input::-webkit-input-placeholder {
  color: rgb(65, 60, 60);
}
input:-ms-input-placeholder {
  color: rgb(65, 60, 60);
}
input::placeholder {
  color: rgb(65, 60, 60);
}
.SignUp_submitButton__WpAnx {
  text-align: center;
  color: white;
  background-color: #062849;
  border: none;
  padding: 5px;
  width: 30%;
  /* margin-left: 35%; */
}
.SignUp_rememberMe__27btm {
  margin: 0;
  margin-left: 5px;
}

.SignUp_signInWithGoogleButton__3rb-8 {
  border: none;
  padding: 5px;
  padding-left: 16px;
  width: 50%;
  background-color: #e7e7e7;
  border-radius: 1px;
  margin-left: 25%;
  margin-top: 15%;
}
.SignUp_signInWithGoogleButton__3rb-8 img {
  width: 10%;
  height: 10%;
  float: left;
}
.SignUp_signInWithGoogleButton__3rb-8 span {
  margin: 0;
  padding: 0;
  padding-left: 16px;
  float: left;
}
.SignUp_forms__Xr-DI{
  display:grid;
  grid-template-columns:1fr 1fr;
  grid-gap:10px;
}
@media screen and (max-width: 600px) {
  .SignUp_signUpBox__-ktoT {
    margin-top: 30%;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr 1fr;
  }
  .SignUp_loginButtons__iqha_ {
    border: none;
    height: 100%;
    width: 100%;
    margin-top: auto;
  }
  .SignUp_oAuth__Etusa {
    height: 100%;
    width: 100%;
    margin-top: auto;
    grid-row: 3/4;
    grid-column: 1/2;
  }
  .SignUp_signInWithGoogleButton__3rb-8 {
    margin-top: 10%;
    height: 22%;
    padding: 0;
  }

  .SignUp_signInWithGoogleButton__3rb-8 img {
    width: 27%;
    height: 70%;
    padding-left: 16px;
    float: left;
  }
  .SignUp_signInWithGoogleButton__3rb-8 span {
    padding-left: 10px;
    padding-top: 2px;
  }
  .SignUp_submitButton__WpAnx {
    margin-top: 5%;
  }
  
}

@media screen and (max-width: 1000px) {
  .SignUp_signInWithGoogleButton__3rb-8 span {
    font-size: 50%;
    padding-left: 10px;
    padding-top: 2px;
  }
  .SignUp_signUpBox__-ktoT {
    margin-top: 15%;
  }
  .SignUp_forms__Xr-DI{
    grid-template-columns: 1fr;

  }
}

.ForgotPassword_loginBox__28qex {
  width: 75%;
  margin: auto;
  margin-top: 50px;
  background: #f0f0f0;
  box-shadow: 2px 2px 1px 1px grey;
  padding: 20px;
  min-height: 5em;
}
.ForgotPassword_oAuth__pi9tz {
  grid-row: 2/3;
  grid-column: 2/3;
  height: 90%;
  display: block;
  -webkit-justify-content: center;
          justify-content: center;
}
.ForgotPassword_loginBox__28qex {
  text-align: center;
  padding-top: 25px;
  font-size: 250%;
  color: #073563;
  grid-row: 1/2;
  grid-column: 1/3;
}
.ForgotPassword_col-sm__2MDZM {
  background-color: red;
}
form {
  width: 100%;
  height: 100%;
}
.ForgotPassword_typingInput__W0-Hd {
  border: none;

  width: 60%;
  padding: 10px;
  padding-left: 10px;
  margin-bottom: 10px;
}
.ForgotPassword_checkingInput__4JdOC {
  margin-left: 20%;
  margin-bottom: 5%;
}
input::-webkit-input-placeholder {
  color: rgb(65, 60, 60);
}
input:-ms-input-placeholder {
  color: rgb(65, 60, 60);
}
input::placeholder {
  color: rgb(65, 60, 60);
}
.ForgotPassword_submitButton__2WG8m {
  text-align: center;
  color: white;
  background-color: #062849;
  border: none;
  padding: 5px;
  width: 30%;
}
.ForgotPassword_rememberMe__1VYeT {
  margin: 0;
  margin-left: 5px;
}

.ForgotPassword_signInWithGoogleButton__2udU5 {
  border: none;
  padding: 5px;
  padding-left: 16px;
  width: 50%;
  background-color: #e7e7e7;
  border-radius: 1px;
  margin-left: 25%;
  margin-top: 15%;
}
.ForgotPassword_signInWithGoogleButton__2udU5 img {
  width: 10%;
  height: 10%;
  float: left;
}
.ForgotPassword_signInWithGoogleButton__2udU5 span {
  margin: 0;
  padding: 0;
  padding-left: 16px;
  float: left;
}
@media screen and (max-width: 1000px) {
  .ForgotPassword_signInWithGoogleButton__2udU5 span {
    font-size: 50%;
    padding-left: 10px;
    padding-top: 2px;
  }
  .ForgotPassword_loginBox__28qex {
    margin-top: 15%;
  }
}
@media screen and (max-width: 600px) {
  .ForgotPassword_loginBox__28qex {
    margin-top: 30%;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr 1fr;
  }
  .ForgotPassword_loginButtons__1CrUE {
    border: none;
    height: 100%;
    width: 100%;
    margin-top: auto;
  }
  .ForgotPassword_oAuth__pi9tz {
    height: 100%;
    width: 100%;
    margin-top: auto;
    grid-row: 3/4;
    grid-column: 1/2;
  }
  .ForgotPassword_signInWithGoogleButton__2udU5 {
    margin-top: 10%;
    height: 22%;
    padding: 0;
  }

  .ForgotPassword_signInWithGoogleButton__2udU5 img {
    width: 27%;
    height: 70%;
    padding-left: 16px;
    float: left;
  }
  .ForgotPassword_signInWithGoogleButton__2udU5 span {
    padding-left: 10px;
    padding-top: 2px;
  }
  .ForgotPassword_submitButton__2WG8m {
    margin-top: 5%;
  }
}

.DashboardComponent_navMenu__3is3K {
  background-color: var(--accent-tealBlue);
  width: 300px;
  height: 30em;
  margin: 0 0 3em 2em;
  box-shadow: 5px 5px 5px grey;
  padding: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.DashboardComponent_navMenuItems__hB4si {
  width: 100%;
  padding: 0;
  margin: 0;
}

.DashboardComponent_dashText__BnFt5 {
  list-style: none;
  padding: 10px 10px 10px;
  width: 100%;
  /* height: 100%; */
  color: #f5f5f5;
}

.DashboardComponent_dashText__BnFt5:hover {
  background-color: var(--accent-blue);
  text-decoration: none;
}

.DashboardComponent_activeTab__3MMAV {
  background-color: #598392;
  width: 100%;
}

.DashboardComponent_image__39KL4 {
  width: auto;
  max-height: 30px;
  min-height: 200px;
  object-fit: cover;
  margin-bottom: 5px;
}

.Dashboard_grid__3A7xb {
  display: grid;
  grid-template-columns: 1fr 2fr;
  width: 100vw;
  height: 90vh;
  padding: 20px;
  grid-gap: 20px;
}
.Dashboard_options__3O5jY {
  width: 100%;
  padding: 10px;
  background-color: #f0f0f0;
  text-align: center;
  box-shadow: 1px 1px 1px 1px grey;
}
.Dashboard_options__3O5jY {
  background-color: #001e3d;
  color: white;
}
.Dashboard_options__3O5jY {
  text-align: left;
  padding: 2%;
  padding-left: 4%;
  margin-top: 2vh;
  margin-bottom: 2vh;
  width: 80%;
  margin-left: 10%;
}

.Dashboard_toggle__2vibC img {
  text-align: center;
}
.Dashboard_content__1eYVU {
  padding-left: 20px;
}

.Dashboard_title__1M5b8 {
  font-weight: 900;
}

.Dashboard_paragraph__1iUnl {
  word-wrap: break-word;
  font-size: 16px;
}

.Dashboard_box__24_p- {
  width: 100%;
  height: 175px;
}

.Dashboard_button__2BlDU {
  background-color: #001e3d;
  color: white;
  border: none;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  /* width:400px; */
  height: 50px;
  font-size: medium;
}

.Dashboard_buttons__1uvwi {
  display: -webkit-flex;
  display: flex;
}

.Dashboard_dashCard__2TggP {
  padding: 30px;
}

.Dashboard_studentCard__2QvUj {
  background-color: var(--accent-tealBlue);
  width: 23em;
  height: 15em;
  padding: 30px;
  font-size: 1em;
  color: white;
  box-shadow: 2px 2px 2px 2px grey;
}

@media (max-width: 1400px) {
  .Dashboard_dashCard__2TggP {
    padding: 30px;
    width: 50%;
  }
}

@media (max-width: 900px) {
  .Dashboard_grid__3A7xb {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr;
    margin-top: 7vw;
    width: 100vw;
    height: 90vh;
    padding: 20px;
    grid-gap: 20px;
  }
  .Dashboard_dashCard__2TggP {
    padding: 0px;
    width: 80%;
  }
}

.DashboardComponent_navMenu__20yGs {
  background-color: var(--accent-tealBlue);
  width: 300px;
  height: 30em;
  margin: 0 0 3em 2em;
  box-shadow: 5px 5px 5px grey;
  padding: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.DashboardComponent_navMenuItems__20QOM {
  width: 100%;
  padding: 0;
  margin: 0;
}

.DashboardComponent_dashText__3Kvvj {
  list-style: none;
  padding: 10px 10px 10px;
  width: 100%;
  /* height: 100%; */
  color: #f5f5f5;
}

.DashboardComponent_dashText__3Kvvj:hover {
  background-color: #598392;
  text-decoration: none;
}

.DashboardComponent_activeTab__U4P41 {
  background-color: #598392;
  width: 100%;
}

.DashboardComponent_image__1vAJy {
  width: auto;
  max-height: 30px;
  min-height: 200px;
  object-fit: cover;
  margin-bottom: 5px;
}

.SignUp_signUpBox__yAibL {
  width: 75%;
  margin: auto;
  background: #f0f0f0;
  box-shadow: 2px 2px 2px 2px grey;
  padding: 20px;
}
.SignUp_oAuth__3FoTN {
  grid-row: 2/3;
  grid-column: 2/3;
  height: 90%;
  display: block;
  -webkit-justify-content: center;
          justify-content: center;
}
.SignUp_signUpBox__yAibL {
  text-align: center;
  padding-top: 25px;
  font-size: 250%;
  color: #073563;
  grid-row: 1/2;
  grid-column: 1/3;
}
.SignUp_col-sm__2UzrX {
  background-color: red;
}
form {
  width: 100%;
  height: 100%;
}
.SignUp_typingInput__2UlWV {
  border: none;
  /* margin-left: 20%; */

  padding: 10px;
  padding-left: 10px;
  margin-bottom: 10px;
}
.SignUp_checkingInput__1LAG5 {
  margin-left: 20%;
  margin-bottom: 5%;
}
input::-webkit-input-placeholder {
  color: rgb(65, 60, 60);
}
input:-ms-input-placeholder {
  color: rgb(65, 60, 60);
}
input::placeholder {
  color: rgb(65, 60, 60);
}
.SignUp_submitButton__3Cu55 {
  text-align: center;
  color: white;
  background-color: #062849;
  border: none;
  padding: 5px;
  width: 30%;
  /* margin-left: 35%; */
}
.SignUp_rememberMe__35nDZ {
  margin: 0;
  margin-left: 5px;
}

.SignUp_signInWithGoogleButton__1c2iT {
  border: none;
  padding: 5px;
  padding-left: 16px;
  width: 50%;
  background-color: #e7e7e7;
  border-radius: 1px;
  margin-left: 25%;
  margin-top: 15%;
}
.SignUp_signInWithGoogleButton__1c2iT img {
  width: 10%;
  height: 10%;
  float: left;
}
.SignUp_signInWithGoogleButton__1c2iT span {
  margin: 0;
  padding: 0;
  padding-left: 16px;
  float: left;
}
.SignUp_forms__1JIb7{
  display:grid;
  grid-template-columns:1fr 1fr;
  grid-gap:10px;
}
@media screen and (max-width: 600px) {
  .SignUp_signUpBox__yAibL {
    margin-top: 30%;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr 1fr;
  }
  .SignUp_loginButtons__1t9cy {
    border: none;
    height: 100%;
    width: 100%;
    margin-top: auto;
  }
  .SignUp_oAuth__3FoTN {
    height: 100%;
    width: 100%;
    margin-top: auto;
    grid-row: 3/4;
    grid-column: 1/2;
  }
  .SignUp_signInWithGoogleButton__1c2iT {
    margin-top: 10%;
    height: 22%;
    padding: 0;
  }

  .SignUp_signInWithGoogleButton__1c2iT img {
    width: 27%;
    height: 70%;
    padding-left: 16px;
    float: left;
  }
  .SignUp_signInWithGoogleButton__1c2iT span {
    padding-left: 10px;
    padding-top: 2px;
  }
  .SignUp_submitButton__3Cu55 {
    margin-top: 5%;
  }
  
}

@media screen and (max-width: 1000px) {
  .SignUp_signInWithGoogleButton__1c2iT span {
    font-size: 50%;
    padding-left: 10px;
    padding-top: 2px;
  }
  .SignUp_signUpBox__yAibL {
    margin-top: 15%;
  }
  .SignUp_forms__1JIb7{
    grid-template-columns: 1fr;

  }
}

.Donate_circle__2AxNl {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 250px;
  width: 250px;
  border-radius: 50%;
  background-color: white;
}

.Donate_donateButton__ulQfB {
  width: 200px;
  text-align: center;
  background-color: rgb(181, 67, 67);
  color: black;
  border: none;
}

.Donate_donateButton__ulQfB:hover {
  width: 200px;
  text-align: center;
  background-color: firebrick;
  color: black;
  border: none;
}

