.theFooter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #001e3d;
  width: 100%;
  color: white;
  padding: 30px;
}

.contactUs {
  height: 80%;
  margin-bottom: 50px;
}

.contactUsContent h1 {
  margin-bottom: 5vh;
}
.contactUsContent {
  align-content: center;
  justify-content: center;
}
.contactUsContent input,
.contactUsContent textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 3vh;
}
.contactUsContent input::placeholder,
.contactUsContent textarea::placeholder {
  color: rgb(31, 30, 30);
}
.submitContact {
  background-color: #e5d5c7;
  border: none;
}

/* .subscribe {
  height: 80%;
  margin-top: 10%;
  grid-row: 1/2;
}

.subscribeContent {
  width: 70%;
  margin-left: 15%;
  margin-top: 7vh;
}

.subscribeContent p {
  text-align: center;
}

.subscribeContent input {
  width: 100%;
  padding: 10px;
  margin-bottom: 3vh;
} */

.copyright p {
  text-align: center;
}
@media (max-width: 800px) {
  .theFooter {
    display: grid;
  }
  /* .subscribe {
    height: 80%;
    margin-top: 10%;
    grid-row: 2/3;
  }
  .subscribeContent {
    margin-top: 0vh;
  } */
}
