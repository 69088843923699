@import url("https://fonts.googleapis.com/css2?family=Big+Shoulders+Display:wght@400;500;700&family=Open+Sans&family=Shrikhand&display=swap");

:root {
  --background-color: #e5d5c7;
  --accent-blue: #aab5c5;
  --accent-green: #fff1c2;
  --accent-tealBlue: #53616f;
  --button-color: #2c333a;
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--background-color);
  font-family: "Open Sans";
}

.title {
  font-size: 5em;
  text-align: center;
  color: #001e3d;
}

h1 {
  font-family: "Shrikhand", cursive;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  padding-inline: 10em;
  min-height: 30em;
  margin-bottom: 20px;
}

.buttonStyle {
  color: white;
  background-color: var(--button-color);
  border: 1px solid #001e3d;
  border-radius: 0;
  padding: 10px 25px 10px;
  font-family: "Big Shoulders Display", cursive;
  letter-spacing: 0.1em;
}

.buttonStyle:hover {
  color: rgb(245, 239, 239);
  background-color: #073563;
  text-decoration: none;
}

@media screen and (max-width: 420px) {
  .title {
    font-size: 3em;
  }
  .mainContainer {
    padding-inline: 10px;
  }
}
