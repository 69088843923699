.cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-inline: 2%;
}

.edit {
  top: 14%;
  display: flex;
  align-self: center;
  justify-content: center;
  left: 10%;
  font-size: 20px;
  color: black;
}
/* 
.edit :hover {
  cursor: pointer;
} */

.pageTitle {
  font-size: 5rem;
  text-align: center;
  color: #001e3d;
}

.blogCard :hover {
  cursor: pointer;
}

.subtext {
  display: inline;
}

.gridcontainer {
  display: grid;
  grid-template-columns: 400px 400px 400px;
  grid-gap: 10px;
  justify-content: center;
}

@media screen and (max-width: 1000px) {
  .gridcontainer {
    grid-template-columns: 280px;
  }
}
