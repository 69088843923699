.navMenu {
  background-color: var(--accent-tealBlue);
  width: 300px;
  height: 30em;
  margin: 0 0 3em 2em;
  box-shadow: 5px 5px 5px grey;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.navMenuItems {
  width: 100%;
  padding: 0;
  margin: 0;
}

.dashText {
  list-style: none;
  padding: 10px 10px 10px;
  width: 100%;
  /* height: 100%; */
  color: #f5f5f5;
}

.dashText:hover {
  background-color: #598392;
  text-decoration: none;
}

.activeTab {
  background-color: #598392;
  width: 100%;
}

.image {
  width: auto;
  max-height: 30px;
  min-height: 200px;
  object-fit: cover;
  margin-bottom: 5px;
}
