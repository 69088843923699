.navbar {
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.logo {
  padding-left: 50px;
  text-decoration: none;
  color: black;
}

.logo:hover {
  padding-left: 50px;
  text-decoration: none;
  color: black;
}

.menuBars {
  margin-left: 1rem;
  font-size: 1.5rem;
}

.navMenu {
  background-color: #060b26;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
}

.navMenu.active {
  left: 0;
  transition: 350ms;
}

.navTextTop {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0px 8px 0px;
  list-style: none;
}

.navTextTop a {
  text-decoration: none;
  color: #f5f5f5;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
}

.navMenuItems {
  width: 100%;
  padding: 0;
  margin: 0;
}

.navbarToggle {
  background-color: #060b26;
  width: 80%;
  height: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

span {
  margin-left: 16px;
}

@media screen and (max-width: 1000px) {
  .logo {
    padding-left: 10px;
  }
  .logo:hover {
    padding-left: 10px;
  }
}

@media screen and (max-width: 500px) {
  .logo {
    padding-left: 5px;
  }
  .logo:hover {
    padding-left: 5px;
  }
  h3 {
    display: none;
  }
  .navMenu {
    width: 100%;
  }
}
