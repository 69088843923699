@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@800&display=swap");

.grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  width: 100vw;
  height: 90vh;
  padding: 20px;
  grid-gap: 20px;
}
.options {
  width: 100%;
  padding: 10px;
  background-color: #f0f0f0;
  text-align: center;
  box-shadow: 1px 1px 1px 1px grey;
}
.options {
  background-color: #001e3d;
  color: white;
}
.options {
  text-align: left;
  padding: 2%;
  padding-left: 4%;
  margin-top: 2vh;
  margin-bottom: 2vh;
  width: 80%;
  margin-left: 10%;
}

.toggle img {
  text-align: center;
}
.content {
  padding-left: 20px;
}

.title {
  font-weight: 900;
}

.paragraph {
  word-wrap: break-word;
  font-size: 16px;
}

.box {
  width: 100%;
  height: 175px;
}

.button {
  background-color: #001e3d;
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  /* width:400px; */
  height: 50px;
  font-size: medium;
}

.buttons {
  display: flex;
}

.dashCard {
  padding: 30px;
}

.studentCard {
  background-color: var(--accent-tealBlue);
  width: 23em;
  height: 15em;
  padding: 30px;
  font-size: 1em;
  color: white;
  box-shadow: 2px 2px 2px 2px grey;
}

@media (max-width: 1400px) {
  .dashCard {
    padding: 30px;
    width: 50%;
  }
}

@media (max-width: 900px) {
  .grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr;
    margin-top: 7vw;
    width: 100vw;
    height: 90vh;
    padding: 20px;
    grid-gap: 20px;
  }
  .dashCard {
    padding: 0px;
    width: 80%;
  }
}
