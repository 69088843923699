.contain {
  margin-left: 17%;
  margin-right: 17%;
  margin-bottom: 10%;
}

.blog {
  background-color: white;
  padding: 4%;
}
.content {
  margin: 7%;
  margin-bottom: 10%;
}

.title {
  text-align: center;
  font-size: 3rem;
}

.row {
  margin-top: 30px;
}

.text {
  padding-bottom: 10%;
  border-bottom: 1px solid black;
}

.icon {
  display: flex;
  align-self: center;
  justify-content: center;
  padding-right: 5px;
}

.button {
  background-color: #696464;
  color: rgb(248, 243, 236);
  text-align: center;
  width: 100px;
  height: 50px;
  border: none;
  margin-top: 25px;
  margin-bottom: 25px;
}

.edit {
  font-size: 40px;
  margin-top: 20px;
  font-weight: bold;
}

@media screen and (max-width: 420px) {
  .buttons {
    justify-self: center;
  }
}
