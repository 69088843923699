.pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #00cae9;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
  z-index: 0;
}
.pin:after {
  content: "";
  width: 14px;
  height: 14px;
  margin: 8px 0 0 8px;
  background: #e6e6e6;
  position: absolute;
  border-radius: 50%;
}
.pin:hover .modal {
  display: block;
}

.modal {
  background-color: white;
  position: absolute;
  transform: rotate(45deg) translateX(-30%);
  display: none;
  border-radius: 10px;
}
