.navbarbuttonstyling {
  color: white;
  background-color: #001e3d;
  border: 1px solid #001e3d;
  margin-left: 1vw;
  border-radius: 0;
}

.navbarbuttonstyling:hover {
  color: rgb(245, 239, 239);
  background-color: #073563;
}

.navLink {
  color: white;
}
#navs {
  z-index: 2;
}
@media screen and (max-width: 1000px) {
  .rftstitle {
    font-size: 80%;
  }
  .navbarbuttonstyling {
    text-align: center;
    margin-left: 1vw;
    border-radius: 0;
    font-size: 50%;
  }
}
@media screen and (max-width: 500px) {
  .rftstitle {
    display: none;
  }
  .navbarbuttonstyling {
    text-align: center;
    margin-left: 1vw;
    border-radius: 0;
  }
  .navbar {
    display: flex;
    justify-content: flex-end;
  }
  .navbarSupportedContent {
    width: auto;
  }
}
