.title {
  font-size: 40px;
  text-align: center;
  font-weight: bold;
  margin: 20px;
}

.subtitle {
  max-width: 70%;
  text-align: center;
  margin: 20px 0 20px;
}

.container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  background-color: var(--accent-tealBlue);
  padding: 60px;
  margin-bottom: 40px;
}

.text {
  color: white;
}

.clicks {
  background-color: #696464;
  color: rgb(248, 243, 236);
  text-align: center;
  width: 200px;
  height: 50px;
  border: none;
}

.button {
  display: block;
  text-align: center;
  margin: 30px;
}

#moreInfo {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 900px) {
  .container {
    grid-template-columns: 1fr;
  }
  .text {
    text-align: center;
  }
}
