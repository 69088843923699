.title {
  font-size: 60px;
  text-align: center;
  font-weight: bold;
  margin: 20px;
}

.list {
  list-style: none;
  justify-self: center;
  margin: 0;
  padding: 0;
  text-align: center;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.map {
  height: 80vh;
}

@media screen and (max-width: 900px) {
  .map {
    width: 96%;
  }
}
